import React from 'react'
import Home  from "./components/home/home";


function App() {
  return (
    <React.Fragment>
      <Home />
    </React.Fragment>
  )
}

export default App
