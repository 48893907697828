import React from 'react'
import './footer.css'
function Footer() {
    return (
        <div className="footer">   
            ©2022 Hrudesh Lenka
        </div>
    )
}

export default Footer
