import React from 'react'
import { SocialData } from '../../data/social'
import './social-contact.css'
function SocialContact() {
    const data = SocialData
    return (
        <div className="social-contact">
            {data.map((item)=>{
                return (
                <a href={item.link}>
                    <div className='social-icon-div'>
                        <img src={item.icon} alt="social" className="social-icon"></img>
                    </div>    
                </a>
                    )
            })}
        </div>
    )
}

export default SocialContact
